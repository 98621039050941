import { createStore, Store } from 'vuex'
import { InjectionKey } from 'vue'

export interface State {
  sidebarVisible: boolean
  sidebarUnfoldable: boolean
  toastMessage: any[]
  visibleSpinnerCount: number
  visibleConfirmModal: boolean
  confirmMessage: string
  confirmOKFunction: Function | undefined
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    sidebarVisible: true,
    sidebarUnfoldable: false,
    toastMessage: [],
    visibleSpinnerCount: 0,
    visibleConfirmModal: false,
    confirmMessage: '',
    confirmOKFunction: undefined,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    showSpinner(state) {
      state.visibleSpinnerCount++
    },
    hideSpinner(state) {
      state.visibleSpinnerCount--
    },
    pushToast(state, payload: any) {
      state.toastMessage.push(payload)
    },
    hideToast(state, payload) {
      state.toastMessage[payload.index].removed = true
      // 全て削除時はクリアする
      if (state.toastMessage.filter((item) => !item.removed).length === 0) {
        state.toastMessage = []
      }
    },
    showConfirm(state, payload) {
      state.confirmMessage = payload.message
      state.confirmOKFunction = payload.okFunction
      state.visibleConfirmModal = true
    },
    hideConfirm(state, payload) {
      state.visibleConfirmModal = false
      if (payload.command === 'ok' && state.confirmOKFunction) {
        state.confirmOKFunction()
        state.confirmOKFunction = undefined
      }
    },
  },
  actions: {},
  modules: {},
})
