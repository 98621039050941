import { AuthResponse, createClient, User } from '@supabase/supabase-js'
import { Database } from './schema'

const supabase = createClient<Database>(
  process.env.VUE_APP_SUPABASE_URL as string,
  process.env.VUE_APP_SUPABASE_KEY as string,
)

async function doLogin(email: string, password: string) {
  const { error, data } = await supabase.auth.signInWithPassword({
    email,
    password,
  })
  if (error) {
    throw error
  }
  return data
}

async function doSignUp(email: string, password: string) {
  const emailRedirectTo = window.location.origin + '/auth_redirect.html'
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: { emailRedirectTo },
  })
  if (error) {
    throw error
  }
  return data
}

async function doPasswordReset(email: string) {
  const redirectTo = window.location.origin + '/auth_redirect.html'
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo,
  })
  if (error) {
    throw error
  }
}

async function doUpdateUser(attributes: {
  email?: string
  password?: string
  data?: object
}) {
  const { error } = await supabase.auth.updateUser(attributes)
  if (error) {
    throw error
  }
}

async function doLogout() {
  const { error } = await supabase.auth.signOut()
  if (error) {
    throw error
  }
}

async function getSession() {
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession()
  if (error) {
    throw error
  }
  return session
}

async function getUser(): Promise<User | null> {
  const { error, data } = await supabase.auth.getUser()
  if (error) {
    throw error
  }
  return data.user
}

async function doGetProjects() {
  const { data: projects, error } = await supabase
    .from('projects')
    .select()
    .neq('deleted', true)
    .order('created_at')
  if (error) {
    throw error
  }
  return projects
}

async function doInsertProject({
  name,
  public_key,
  private_key,
  url,
  email,
  icon,
  owner_id,
}: {
  name: string
  public_key: string
  private_key: string
  url?: string
  email?: string
  icon?: string
  owner_id: string
}) {
  const data = {
    name,
    public_key,
    private_key,
    url,
    email,
    icon,
    owner_id,
  }

  const { data: project, error } = await supabase
    .from('projects')
    .insert(data)
    .select()
    .single()
  if (error) {
    throw error
  }
  return project
}

async function doUpdateProject({
  id,
  name,
  public_key,
  private_key,
  url,
  email,
  icon,
  owner_id,
  disabled,
  deleted,
}: {
  id: string
  name?: string
  public_key?: string
  private_key?: string
  url?: string
  email?: string
  icon?: string
  owner_id: string
  disabled?: boolean
  deleted?: boolean
}) {
  const data = {
    name,
    public_key,
    private_key,
    url,
    email,
    icon,
    owner_id,
    disabled,
    deleted,
    updated_at: new Date().toISOString(),
  }

  const { data: project, error } = await supabase
    .from('projects')
    .update(data)
    .eq('id', id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return project
}

async function doGetProjectUsers(project_id: string) {
  const { data: project_users, error } = await supabase
    .from('project_users')
    .select()
    .eq('project_id', project_id)
    .order('created_at')
  if (error) {
    throw error
  }
  return project_users
}

async function doInsertProjectUser({
  project_id,
  user_id,
  admin,
}: {
  project_id: string
  user_id: string
  admin: boolean
}) {
  const data = {
    project_id,
    user_id,
    admin,
  }

  const { data: projectUser, error } = await supabase
    .from('project_users')
    .insert(data)
    .select()
    .single()
  if (error) {
    throw error
  }
  return projectUser
}

async function doUpdateProjectUser({
  project_id,
  user_id,
  admin,
  disabled,
}: {
  project_id: string
  user_id: string
  admin?: boolean
  disabled?: boolean
}) {
  const data = {
    project_id,
    user_id,
    admin,
    disabled,
    updated_at: new Date().toISOString(),
  }

  const { data: projectUser, error } = await supabase
    .from('project_users')
    .update(data)
    .eq('project_id', project_id)
    .eq('user_id', user_id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return projectUser
}

async function doDeleteProjectUser({
  project_id,
  user_id,
}: {
  project_id: string
  user_id: string
}) {
  const { error } = await supabase
    .from('project_users')
    .delete()
    .eq('project_id', project_id)
    .eq('user_id', user_id)
  if (error) {
    throw error
  }
}

async function doGetSubscribers(project_id: string) {
  const { data: subscribers, error } = await supabase
    .from('subscribers')
    .select()
    .eq('project_id', project_id)
    .neq('deleted', true)
    .order('created_at')
  if (error) {
    throw error
  }
  return subscribers
}

async function doUpdateSubscriber({
  id,
  disabled,
  deleted,
}: {
  id: string
  disabled?: boolean
  deleted?: boolean
}) {
  const data = {
    disabled,
    deleted,
    updated_at: new Date().toISOString(),
  }

  const { data: project, error } = await supabase
    .from('subscribers')
    .update(data)
    .eq('id', id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return project
}

async function doGetNotification(id: string) {
  const { data: notification, error } = await supabase
    .from('notifications')
    .select()
    .eq('id', id)
    .neq('deleted', true)
    .single()
  if (error) {
    throw error
  }
  return notification
}

async function doGetNotifications(project_id: string) {
  const { data: notifications, error } = await supabase
    .from('notifications')
    .select()
    .eq('project_id', project_id)
    .neq('deleted', true)
    .order('created_at')
  if (error) {
    throw error
  }
  return notifications
}

async function doInsertNotification({
  project_id,
  payload,
}: {
  project_id: string
  payload: string
}) {
  const data = {
    project_id,
    payload,
  }

  const { data: notification, error } = await supabase
    .from('notifications')
    .insert(data)
    .select()
    .single()
  if (error) {
    throw error
  }
  return notification
}

async function doUpdateNotification({
  id,
  payload,
  disabled,
  deleted,
}: {
  id: string
  payload?: string
  disabled?: boolean
  deleted?: boolean
}) {
  const data = {
    payload,
    disabled,
    deleted,
    updated_at: new Date().toISOString(),
  }

  const { data: notification, error } = await supabase
    .from('notifications')
    .update(data)
    .eq('id', id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return notification
}

async function doGetDelivery(id: string) {
  const { data: delivery, error } = await supabase
    .from('deliveries')
    .select()
    .eq('id', id)
    .single()
  if (error) {
    throw error
  }
  return delivery
}

async function doGetDeliveries(project_id: string, notification_id: string) {
  const { data: deliveries, error } = await supabase
    .from('deliveries')
    .select()
    .eq('project_id', project_id)
    .eq('notification_id', notification_id)
    .neq('deleted', true)
    .order('created_at')
  if (error) {
    throw error
  }
  return deliveries
}

async function doInsertDelivery({
  project_id,
  notification_id,
  delivery_mode,
  delivery_time,
  delivered_at,
  delivery_status,
  segment_mode,
  segment_id,
  segment_timing,
}: {
  project_id: string
  notification_id: string
  delivery_mode: string
  delivery_time: string
  delivered_at: string
  delivery_status: string
  segment_mode: string
  segment_id: string
  segment_timing: string
}) {
  const data = {
    project_id,
    notification_id,
    delivery_mode,
    delivery_time,
    delivered_at,
    delivery_status,
    segment_mode,
    segment_id,
    segment_timing,
  }

  const { data: delivery, error } = await supabase
    .from('deliveries')
    .insert(data)
    .select()
    .single()
  if (error) {
    throw error
  }
  return delivery
}

async function doUpdateDelivery({
  id,
  delivery_mode,
  delivery_time,
  delivered_at,
  delivery_status,
  segment_mode,
  segment_id,
  segment_timing,
  disabled,
  deleted,
}: {
  id: string
  delivery_mode?: string
  delivery_time?: string
  delivered_at?: string
  delivery_status?: string
  disabled?: boolean
  deleted?: boolean
  segment_mode?: string
  segment_id?: string
  segment_timing?: string
}) {
  const data = {
    delivery_mode,
    delivery_time,
    delivered_at,
    delivery_status,
    segment_mode,
    segment_id,
    segment_timing,
    disabled,
    deleted,
    updated_at: new Date().toISOString(),
  }

  const { data: delivery, error } = await supabase
    .from('deliveries')
    .update(data)
    .eq('id', id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return delivery
}

async function doGetDeliverySubscribers(
  project_id: string,
  delivery_id: string,
) {
  const { data: delivery_subscribers, error } = await supabase
    .from('delivery_subscribers')
    .select()
    .eq('project_id', project_id)
    .eq('delivery_id', delivery_id)
    .order('created_at')
  if (error) {
    throw error
  }
  return delivery_subscribers
}

async function doInsertDeliverySubscriber({
  project_id,
  delivery_id,
  subscriber_id,
  delivery_state,
  delivered_at,
}: {
  project_id: string
  delivery_id: string
  subscriber_id: string
  delivery_state: string
  delivered_at: string
}) {
  const data = {
    project_id,
    delivery_id,
    subscriber_id,
    delivery_state,
    delivered_at,
  }

  const { data: delivery_subscribers, error } = await supabase
    .from('delivery_subscribers')
    .insert(data)
    .select()
    .single()
  if (error) {
    throw error
  }
  return delivery_subscribers
}

async function doUpdateDeliverySubscriber({
  project_id,
  delivery_id,
  subscriber_id,
  delivery_state,
  delivered_at,
  disabled,
}: {
  project_id: string
  delivery_id: string
  subscriber_id: string
  delivery_state: string
  delivered_at: string
  disabled?: boolean
}) {
  const data = {
    project_id,
    delivery_id,
    subscriber_id,
    delivery_state,
    delivered_at,
    disabled,
    updated_at: new Date().toISOString(),
  }

  const { data: delivery_subscribers, error } = await supabase
    .from('delivery_subscribers')
    .update(data)
    .eq('project_id', project_id)
    .eq('delivery_id', delivery_id)
    .eq('subscriber_id', subscriber_id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return delivery_subscribers
}

async function doDeleteDeliverySubscriber({
  project_id,
  delivery_id,
  subscriber_id,
}: {
  project_id: string
  delivery_id: string
  subscriber_id: string
}) {
  const { error } = await supabase
    .from('delivery_subscribers')
    .delete()
    .eq('project_id', project_id)
    .eq('delivery_id', delivery_id)
    .eq('subscriber_id', subscriber_id)
  if (error) {
    throw error
  }
}

async function doDeleteDeliverySubscriberAll({
  project_id,
  delivery_id,
}: {
  project_id: string
  delivery_id: string
}) {
  const { error } = await supabase
    .from('delivery_subscribers')
    .delete()
    .eq('project_id', project_id)
    .eq('delivery_id', delivery_id)
  if (error) {
    throw error
  }
}

async function doSendPush({
  project_id,
  delivery_id,
}: {
  project_id: string
  delivery_id: string
}) {
  const { data, error } = await supabase.functions.invoke('send-push', {
    method: 'POST',
    body: { project_id, delivery_id },
  })
  console.log(data, error)
  if (error) {
    throw error
  }
}

async function doSendPushReservations() {
  const { data, error } = await supabase.functions.invoke(
    'send-push-reservations',
    {
      method: 'POST',
      body: {},
    },
  )
  console.log(data, error)
  if (error) {
    throw error
  }
}

async function doGenerateKeys() {
  const { data, error } = await supabase.functions.invoke('generate-keys', {
    method: 'GET',
  })
  console.log(data, error)
  if (error) {
    throw error
  }
  return data
}

async function getProducts() {
  const { data: v_products, error } = await supabase
    .from('v_products')
    .select()
    .order('order_number', { ascending: true })
  if (error) {
    throw error
  }
  return v_products
}

async function getSubscriptions() {
  const { data: subscriptions, error } = await supabase
    .from('subscriptions')
    .select()
    .order('created_at')
  if (error) {
    throw error
  }
  return subscriptions
}

async function getCredits() {
  const { data: credits, error } = await supabase
    .from('credits')
    .select()
    .neq('deleted', true)
    .order('created_at')
  if (error) {
    throw error
  }
  return credits
}

async function getCreditsSummary() {
  const { data: credits_summary, error } = await supabase
    .from('v_credits_summary')
    .select()
  if (error) {
    throw error
  }
  return credits_summary
}

async function getPayments() {
  const { data: payments, error } = await supabase
    .from('payments')
    .select()
    .order('created_at')
  if (error) {
    throw error
  }
  return payments
}

async function createPurchase({
  product_id,
  quantity,
  success_url,
  cancel_url,
  with_cancel_allowed,
}: {
  product_id: string
  quantity: number
  success_url: string
  cancel_url: string
  with_cancel_allowed: boolean
}) {
  const { data, error } = await supabase.functions.invoke('purchase', {
    method: 'POST',
    body: {
      create: {
        product_id,
        quantity,
        success_url,
        cancel_url,
        with_cancel_allowed,
      },
    },
  })
  console.log(data, error)
  if (error) {
    throw error
  }
  return data
}

async function cancelPurchase({
  subscription_id,
}: {
  subscription_id: string
}) {
  const { data, error } = await supabase.functions.invoke('purchase', {
    method: 'POST',
    body: {
      cancel: {
        subscription_id,
      },
    },
  })
  console.log(data, error)
  if (error) {
    throw error
  }
  return data
}

async function doDeleteAccount() {
  const { error } = await supabase.functions.invoke('delete-account', {
    method: 'POST',
  })
  if (error) {
    throw error
  }
}

export default {
  doLogin,
  doSignUp,
  doLogout,
  doPasswordReset,
  doUpdateUser,
  getSession,
  getUser,
  doGetProjects,
  doInsertProject,
  doUpdateProject,
  doGetProjectUsers,
  doInsertProjectUser,
  doUpdateProjectUser,
  doDeleteProjectUser,
  doGetSubscribers,
  doUpdateSubscriber,
  doGetNotification,
  doGetNotifications,
  doInsertNotification,
  doUpdateNotification,
  doGetDelivery,
  doGetDeliveries,
  doInsertDelivery,
  doUpdateDelivery,
  doGetDeliverySubscribers,
  doInsertDeliverySubscriber,
  doUpdateDeliverySubscriber,
  doDeleteDeliverySubscriber,
  doDeleteDeliverySubscriberAll,
  doSendPush,
  doSendPushReservations,
  doGenerateKeys,
  getProducts,
  getSubscriptions,
  getCredits,
  getCreditsSummary,
  getPayments,
  createPurchase,
  cancelPurchase,
  doDeleteAccount,
}
