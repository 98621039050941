<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle
      placement="bottom-end"
      class="py-0"
      :caret="false"
      @click.prevent=""
    >
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem style="cursor: pointer">
        <CIcon icon="cil-user" /> {{ user.email }}
      </CDropdownItem>
      <CDropdownItem style="cursor: pointer" @click="goMyPage()">
        <CIcon icon="cil-notes" /> MyPage
      </CDropdownItem>
      <CDropdownItem style="cursor: pointer" @click="doLogout()">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import supabase from '../supabase'
import { ref } from 'vue'
import avatar from '@/assets/images/avatars/user.png'
import router from '@/router'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    const user = ref({})
    ;(async () => {
      try {
        user.value = await supabase.getUser()
      } catch (e) {
        await router.replace('/pages/login')
      }
    })()
    return {
      avatar: avatar,
      itemsCount: 42,
      user,
      goMyPage: () => {
        router.replace({ path: '/mypage' })
      },
      doLogout: async () => {
        await supabase.doLogout()
        await router.replace('/pages/login')
      },
    }
  },
}
</script>
