import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import supabase from '../supabase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/projects',
        name: 'Projects',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Projects.vue'),
      },
      {
        path: '/mypage',
        name: 'MyPage',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/MyPage.vue'),
      },
      {
        path: '/guide',
        name: 'Guide',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Guide.vue'),
      },
      {
        path: '/confirmDeleteAccount',
        name: '退会の確認',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/ConfirmDeleteAccount.vue'
          ),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: 'resetPasswordRequest',
        name: 'ResetPasswordRequest',
        component: () => import('@/views/pages/ResetPasswordRequest'),
      },
      {
        path: 'resetPasswordUpdate',
        name: 'ResetPasswordUpdate',
        component: () => import('@/views/pages/ResetPasswordUpdate'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const session = await supabase.getSession()
  if (session || (to.name !== 'Dashboard' && to.name !== 'Projects')) {
    next()
  } else {
    next({ path: '/pages/login' })
  }
})

export default router
