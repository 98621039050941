export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Projects',
    to: '/projects',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Guide',
    to: '/guide',
    icon: 'cil-flag-alt',
  },
]
