import { createApp } from 'vue'
import App from './App.vue'
// @ts-ignore
import router from './router'
// @ts-ignore
import { store, key } from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
// @ts-ignore
import { iconsSet as icons } from '@/assets/icons'

const app = createApp(App)
app.use(store, key)
app.use(router)
// @ts-ignore
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
