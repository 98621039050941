<template>
  <div>
    <!-- スピナーモーダル -->
    <CModal
      backdrop="static"
      alignment="center"
      :visible="$store.state.visibleSpinnerCount > 0"
      content-class-name="bg-transparent border-0"
    >
      <div class="d-flex justify-content-center">
        <CSpinner variant="grow"></CSpinner>
      </div>
    </CModal>

    <!-- 確認モーダル -->
    <CModal
      backdrop="static"
      :visible="$store.state.visibleConfirmModal"
      @close="$store.commit('hideConfirm', { command: 'cancel' })"
    >
      <CModalHeader>
        <CModalTitle></CModalTitle>
      </CModalHeader>
      <CModalBody v-html="$store.state.confirmMessage"></CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          @click="$store.commit('hideConfirm', { command: 'cancel' })"
        >
          Cancel
        </CButton>
        <CButton
          color="primary"
          @click="$store.commit('hideConfirm', { command: 'ok' })"
        >
          OK
        </CButton>
      </CModalFooter>
    </CModal>

    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CToaster placement="top-end">
          <CToast
            :autohide="false"
            :color="toast.color"
            class="text-white align-items-center"
            visible
            v-for="(toast, index) in $store.state.toastMessage"
            :key="index"
            @close="$store.commit('hideToast', { index })"
          >
            <div class="d-flex">
              <CToastBody v-html="toast.message"></CToastBody>
              <CToastClose class="me-2 m-auto" white />
            </div>
          </CToast>
        </CToaster>
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <!--<AppFooter />-->
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
//import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    //AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
}
</script>
